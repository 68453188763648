/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { TIconProps } from './type';
import Image from 'next/image';

export const Icon = ({ styleTheme = 'default', size, width, height, color, name, src, ...otherProps }: TIconProps) => {
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(true);

  if (src) {
    return (
      <Image
        width={150}
        height={21}
        css={css`
          width: ${size ?? width ?? '100%'};
          height: ${size ?? height ?? '100%'};
          * {
            fill: ${color};
          }
        `}
        src={src}
        {...otherProps}
      />
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setLoading(true);

    const importIcon = async () => {
      try {
        const { default: namedImport } = await import(`./icon/${styleTheme}/${name}.svg`);

        ImportedIconRef.current = namedImport;
      } catch (err) {
        // throw err
        try {
          const { default: namedImport } = await import(`./icon/${name}.svg`);

          ImportedIconRef.current = namedImport;
        } catch (defaultErr) {
          throw defaultErr;
        }
      } finally {
        setLoading(false);
      }
    };

    importIcon();
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon }: any = ImportedIconRef;

    return (
      <ImportedIcon
        css={css`
          width: ${size ?? width};
          height: ${size ?? height};
          fill: ${color};
          * {
            fill: ${color};
          }
        `}
        {...otherProps}
      />
    );
  }

  return null;
};

export default Icon;
