import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';

const navigationStyle = css`
  .navigation-lists {
    ${flex('row', 'flex-start', 'center')};
    a {
      ${font('1.3rem', 400, colors.$color_666, '2rem')};
    }
    .last-content {
      a {
        pointer-events: none;
        ${font('1.3rem', 500, colors.$color_000, '2rem')};
      }
    }
    .navigation-list {
      ${flex('row', 'flex-start', 'center')};
      .ic {
        margin: 0 0.8rem;
      }
      .text {
        ${font('1.3rem', 500, colors.$color_000, '2rem')};
        letter-spacing: -2.5%;
        &.link {
          font-weight: 400;
          color: ${colors.$color_666};
        }
      }
    }
  }
`;

export default navigationStyle;
