import classNames from 'classnames';
import React from 'react';
import { BreadcrumbsProps } from './type';

function Breadcrumbs({ items = [], separator, className, ...props }: BreadcrumbsProps) {
  const lastIdx = items?.length - 1;

  return (
    <ul className={classNames(className, 'haefe-breadcrumbs-wrapper')} {...props}>
      {items?.map((item: React.ReactNode, index: number) => (
        <React.Fragment key={index}>
          {index !== 0 && <li className="haefe-breadcrumbs-separator">{separator}</li>}
          <li className={classNames('haefe-breadcrumbs-item', { 'last-content': index === lastIdx })}>{item}</li>
        </React.Fragment>
      ))}
    </ul>
  );
}

export default Breadcrumbs;
