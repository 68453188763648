import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import navigationStyle from './style';
import { MENU } from '@/constants/menu';
import { WWN_HOST } from '@/config';
import BreadCrumbs from '@components/haeLibraryRefactored/Breadcrumbs';
import Link from 'next/link';
import Icon from '@/components/units/icon';
import { useTranslation } from 'react-i18next';

const Navigation = () => {
  const { pathname, asPath, query } = useRouter();
  const router = useRouter();
  const { t } = useTranslation();

  const linkName = [
    <Link
      href={{
        pathname: `${WWN_HOST}/${router.query.lang}`,
      }}
      key="home"
    >
      {t('Home')}
    </Link>,
    ...(pathname === '/' || pathname === '/compare'
      ? [
          <Link href={{ pathname: '/', query: { lang: router?.query?.lang } }} key="ctb">
            Cl!ck to Buy
          </Link>,
        ]
      : []),
  ];

  const RenderBreadcrumbs = useMemo(() => {
    const pathNameList = asPath.split(/[?/]/).filter((item: string) => item !== '');

    let children = MENU;
    let showBreadcrumbs = true;

    const breadcrumbsList: React.ReactElement[] = pathNameList.reduce((tempList: any[], pathItem: string) => {
      const menuItem = children[pathItem];

      if (!!menuItem) {
        showBreadcrumbs = menuItem.showBreadcrumbs ?? true;
        tempList.push(
          <Link
            key={menuItem.path}
            href={{
              pathname: menuItem.path,
              ...(menuItem?.remainQuery ? { query } : { query: { lang: query?.lang?.toString() } }),
            }}
          >
            {menuItem.i18Key ? t(menuItem.i18Key) : menuItem.name}
          </Link>,
        );
        children = menuItem?.children ?? {};
      }

      return tempList;
    }, linkName);

    if (!showBreadcrumbs) return null;

    return (
      <>
        <BreadCrumbs
          className="navigation-lists"
          items={breadcrumbsList}
          separator={<Icon className="ic ic-nav-arrow" name="ic_nav_arrow" />}
        />
      </>
    );
  }, [asPath, linkName, MENU]);

  useEffect(() => {
    const customWindow = window as Global.IWindow;
    const breadCrumbList: React.ReactElement[] = [];

    if (!linkName) return;
    linkName.map(d => breadCrumbList.push(d.props.children));
    const breadCrumb = breadCrumbList.join(':');

    customWindow.HMG_COMMON_TAG.breadcrumb = breadCrumb;
  }, [linkName]);

  return (
    <div className="navigation-box" css={navigationStyle}>
      {RenderBreadcrumbs}
    </div>
  );
};

export default Navigation;
